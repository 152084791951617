const env = process.env.VERCEL_ENV || "development";
const isDevelopment = env === "development";
const isPreview = env === "preview";
const isProduction = env === "production";

const protocol = isDevelopment ? "http" : "https";
const port = process.env.PORT || 3000;

const envSuffix = isProduction ? "" : "-dev";
const apiBaseUrl = `https://api${envSuffix}.everbloom.app/v1`;
const fclApiBaseUrl = `https://fcl.api${envSuffix}.everbloom.app/v1`;

const host = isDevelopment ? `localhost:${port}` : `youtube.everbloom.app`;
const baseUrl = `${protocol}://${host}`;

const discordBotToken = process.env.DISCORD_BOT_TOKEN;
const discordClientId = process.env.DISCORD_CLIENT_ID;
const discordClientSecret = process.env.DISCORD_CLIENT_SECRET;
const discordRedirectUrl = `${baseUrl}/api/discord/auth`;
const discordAuthUrl = `https://discord.com/api/oauth2/authorize?prompt=consent&response_type=code&permissions=268436496&scope=bot%20guilds%20guilds.join%20identify%20email&client_id=${discordClientId}&redirect_uri=${encodeURIComponent(
  discordRedirectUrl
)}`;
const googleWebClientId = process.env.GOOGLE_WEB_CLIENT_ID;

const apiUrl = typeof window !== "undefined" ? "/api" : apiBaseUrl;
const apiFclUrl = typeof window !== "undefined" ? "/api-fcl" : fclApiBaseUrl;

// Flowscan
const flowscanUrl = isProduction
  ? "https://flowscan.org/transaction/"
  : "https://testnet.flowscan.org/transaction/";

const expressCheckoutConfig = {
  applePay: {
    supportedMethod:
      process.env.APPLE_PAY_SUPPORTED_METHOD || "https://apple.com/apple-pay",
    version: Number(process.env.APPLE_PAY_VERSION) || 3,
    merchantId:
      process.env.APPLE_PAY_MERHCANT_ID || "merchant.studio.sweet.Strucc",
    merchantName:
      process.env.APPLE_PAY_MERHCANT_NAME || "Everbloom Creative Inc",
  },
  googlePay: {
    version: Number(process.env.GOOGLE_PAY_VERSION) || 2,
    environment: isProduction ? "PRODUCTION" : "TEST",
    checkoutOption:
      process.env.GOOGLE_PAY_CHECKOUT_OPTION || "COMPLETE_IMMEDIATE_PURCHASE",
    merchantId: process.env.GOOGLE_PAY_MERHCANT_ID || "BCR2DN4TQCDJXYJB",
    merchantName:
      process.env.GOOGLE_PAY_MERHCANT_NAME || "Everbloom Creative Inc",
    gateway: process.env.GOOGLE_PAY_GATEWAY || "checkoutltd",
    // TODO(shehryar): use public key from google pay as gatewayMerchantId
    gatewayMerchantId: process.env.GOOGLE_PAY_GATEWAY_MERCHANT_ID || "XXXXX",
  },
};

module.exports = {
  apiUrl,
  apiFclUrl,
  apiBaseUrl,
  fclApiBaseUrl,
  baseUrl,
  discord: {
    authUrl: discordAuthUrl,
    botToken: discordBotToken, // SERVER ONLY
    clientId: discordClientId,
    clientSecret: discordClientSecret, // SERVER ONLY
    redirectUrl: discordRedirectUrl,
  },
  google: {
    webClientId: googleWebClientId,
  },
  isProduction,
  expressCheckoutConfig,
  envSuffix,
  flowscanUrl,
};
