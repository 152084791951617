import Head from 'next/head';
import { FC } from 'react';

interface Props {
  title: string;
  description?: string;
  image?: string;
  url?: string;
}

const SEO: FC<Props> = ({ title, description, image, url }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta
        name="description"
        content={
          description ??
          'Empowering creative people and projects through shared ownership. We enable anyone with a smartphone to create, collect, and monetize digital collectibles.'
        }
      />
      <meta property="og:title" content={`${title}`} />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content={
          description ??
          'Empowering creative people and projects through shared ownership. We enable anyone with a smartphone to create, collect, and monetize digital collectibles.'
        }
      />
      <meta name="og:image" content={image ?? '/images/og-image-1.png'} />
      <meta name="twitter:card" content="summary_large_image" />
    </Head>
  );
};

export default SEO;
