import axios from "axios";
import { setCookie } from "nookies";

import { apiUrl as baseUrl, baseUrl as redirectUriBase } from "@/config";
import { AuthResponse, OAuthCodeRequest } from "@/interfaces/Auth";

type Props = { baseUrl: string };

let instance: AuthService;

export class AuthService {
  private baseUrl: string;

  constructor({ baseUrl }: Props) {
    if (instance) {
      throw new Error("Can only create one instance");
    }
    this.baseUrl = baseUrl;
    instance = this;
  }

  private get headers() {
    return {
      "Content-Type": "application/json",
    };
  }

  async authGoogle({
    oAuthCode,
    redirectUri = redirectUriBase,
    baseUrl = this.baseUrl,
  }: OAuthCodeRequest): Promise<AuthResponse> {
    const { data } = await axios.post(
      `${baseUrl}/auth/google`,
      {
        oAuthCode,
        redirectUri,
      },
      {
        headers: this.headers,
      }
    );
    const authResponse: AuthResponse = data?.data;

    const { accessToken, expiresIn, refreshToken, refreshIn } =
      authResponse || {};
    setCookie(null, "everbloomToken", accessToken, {
      maxAge: expiresIn,
      path: "/",
    });
    setCookie(null, "everbloomRefreshToken", refreshToken, {
      maxAge: refreshIn,
      path: "/",
    });

    return authResponse;
  }
}

const singletonAuthService = Object.freeze(new AuthService({ baseUrl }));

export const getInstance = () => singletonAuthService;

export default singletonAuthService;
