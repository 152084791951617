import Link from "next/link";
import { FC } from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  href: string;
  style?: React.CSSProperties;
}

const LinkStyled: FC<Props> = ({ children, href, style = {} }) => {
  return (
    <Wrapper href={href} style={style} passHref>
      {children}
    </Wrapper>
  );
};

export default LinkStyled;

const Wrapper = styled(Link)`
  text-decoration: none;
  color: var(--black);
`;
