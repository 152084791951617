import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { captureException } from "@sentry/nextjs";

import { useToken } from "./useAuth";

import { apiUrl } from "@/config";

import { Profile } from "@/interfaces/Profile";

export const fetchUser = async (token: string) => {
  try {
    const { data } = await axios.get(`${apiUrl}/me`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      timeout: 15000,
      timeoutErrorMessage: "Timeout fetching user. Please try again.",
    });

    return data?.data?.profile as Profile;
  } catch (error: unknown) {
    captureException(error);

    if (error instanceof Error) {
      console.log(
        "Error fetching - https://api-dev.everbloom.app/v1/me",
        error.message
      );
    }
  }
};

const useUser = () => {
  const everbloomToken = useToken();

  return useQuery(["user"], () => fetchUser(everbloomToken as string), {
    staleTime: 30000,
    enabled: !!everbloomToken,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

export default useUser;
