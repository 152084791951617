import type { AmplitudeClient } from 'amplitude-js';
import { createContext, ReactNode, useCallback, useContext } from 'react';

import useUser from '@/hooks/useUser';
import useConstant from 'use-constant';

interface Props {
  children: ReactNode;
}

interface AmplitudeContext {
  amplitudeInstance?: AmplitudeClient;
  eventProperties?: any;
}

const API_KEY =
  process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production'
    ? '42c05cd50d0fa0c66a13e276e5bdad79'
    : 'f3b9805678aac28c9b8f2b6f1be63c87';

export const AmplitudeContext = createContext<AmplitudeContext>({
  eventProperties: {},
});

export const useAmplitudeContext = () => {
  const context = useContext(AmplitudeContext);
  if (context == null) {
    throw new Error(
      'useAmplitude context must be used within AmplitudeProvider'
    );
  }

  return context;
};

const initAmplitude = (
  amplitudeInstance: AmplitudeClient,
  apiKey: string,
  userId?: string
) => {
  if (apiKey) {
    amplitudeInstance.init(apiKey);
  }
  if (userId) {
    amplitudeInstance.setUserId(userId);
  }
};

const AmplitudeProvider = ({ children }: Props) => {
  const amplitudeInstance = useConstant(
    () => typeof window != 'undefined' && window.amplitude?.getInstance()
  );

  const { data: user } = useUser();

  const init = useCallback(
    () => initAmplitude(amplitudeInstance, API_KEY, user?.id),
    [user?.id, amplitudeInstance]
  );

  if (!amplitudeInstance) {
    return <>{children}</>;
  }

  init();

  return (
    <AmplitudeContext.Provider
      value={{ amplitudeInstance, eventProperties: {} }}
    >
      {children}
    </AmplitudeContext.Provider>
  );
};

export default AmplitudeProvider;
