import { FieldValues, Resolver, SubmitHandler, useForm } from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import { useState } from "react";
import axios, { AxiosError } from "axios";
import styled, { css } from "styled-components";
import CurrencyInput from "react-currency-input-field";
import { useRouter } from "next/router";
import { captureException } from "@sentry/nextjs";

import GoogleButton from "./GoogleButton";
import TopLogos from "./TopLogos";
import TextHr from "../UI/TextHr";
import TextInputField from "../UI/Form/TextInputField";
import Label from "../UI/Form/Label";
import { PrivacyText } from "./PairSuccess";
import Controller from "../UI/Inputs/Controller";

import {
  ButtonStyled,
  ButtonWrapper,
  Container,
  FormStyled,
  Grid,
  StepsContainer,
  StepsCount,
  StepsHeading,
  StepsText,
  StepsWrapper,
  Tagline,
  Title,
  TitleEiko,
  Wrapper,
} from "./Pair.styles";
import useAmplitude from "@/utils/amplitude/useAmplitude";

interface FormValues {
  email: string;
  revenue: string;
  channelUrl: string;
}

type Values = FormValues & FieldValues;

const Pair = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const { push } = useRouter();

  const { logAmplitudeEvent } = useAmplitude();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit: SubmitHandler<FormValues> = async (data: any) => {
    setIsSubmitting(true);

    try {
      await axios.post("/api/yt-form", { ...data });

      await axios.post("/api/mailchimp-email", { ...data });

      await axios.post("/api/discord", {
        email: data.email,
        revenue: data.revenue,
        channelUrl: data.channelUrl,
      });

      logAmplitudeEvent("Creator_Form_Finish");

      push(`/?screen=submitted`);
    } catch (error) {
      captureException(error);
      setIsSubmitting(false);

      if (error instanceof AxiosError) {
        push(`/?screen=error&message=${error?.response?.data.status.message}`);
      }
    }
  };

  return (
    <>
      <Wrapper>
        <Container>
          <Title>
            How much investment <br />
            <TitleEiko>can I receive?</TitleEiko>
          </Title>
          <Grid>
            <StepsWrapper>
              <StepsContainer>
                <StepsCount>1.</StepsCount>
                <div>
                  <StepsHeading>Pair your youtube account</StepsHeading>
                  <StepsText>
                    Use the Google sign-in button to connect your YouTube
                    account for read-only access to the most important info or
                    fill out the form so we can make you a proposal.
                  </StepsText>
                </div>
              </StepsContainer>
              <StepsContainer>
                <StepsCount>2.</StepsCount>
                <div>
                  <StepsHeading>We calculate your funding</StepsHeading>
                  <StepsText>
                    Our algorithm will calculate how much you funding you can
                    receive upfront based on your channel&apos;s performance.
                  </StepsText>
                </div>
              </StepsContainer>
              <StepsContainer style={{ marginBottom: 40 }}>
                <StepsCount>3.</StepsCount>
                <div>
                  <StepsHeading>Explore your options</StepsHeading>
                  <StepsText>
                    We&apos;ll email you three personalized options to chose
                    from, along with suggestions on how you could use the money
                    to grow your channel.
                  </StepsText>
                </div>
              </StepsContainer>
            </StepsWrapper>
            <div>
              <ButtonWrapper>
                <GoogleButton />
                <span
                  style={{
                    textAlign: "center",
                    marginTop: 16,
                    fontSize: "1.2rem",
                    lineHeight: 1.4,
                    color: "var(--black85)",
                  }}
                >
                  Everbloom does not sell your data to third-parties. You can
                  unpair your account at any time.
                </span>
              </ButtonWrapper>
              <TextHr>
                <span>or</span>
              </TextHr>
              {/* @ts-ignore */}
              <FormStyled onSubmit={handleSubmit(onSubmit)}>
                <div
                  style={{ position: "relative" }}
                  onClick={() => {
                    logAmplitudeEvent("Creator_Form_Start");
                  }}
                >
                  <Label>
                    Your YouTube revenue in the past 365 days
                    <Controller
                      {...{
                        control,
                        name: "revenue",
                        fieldName: "Revenue",
                        register,
                        rules: {
                          required: true,
                        },
                        render: ({ onChange, name, value, errors }) => {
                          return (
                            <div style={{ position: "relative" }}>
                              <CurrencyTextInput
                                autoComplete="off"
                                name={name}
                                error={!!errors}
                                value={value}
                                allowNegativeValue={false}
                                allowDecimals={false}
                                onValueChange={(value) => onChange(value)}
                              />
                              {errors?.type === "required" && (
                                <TextInputField.ErrorContainer>
                                  <TextInputField.ErrorText>
                                    Revenue is required
                                  </TextInputField.ErrorText>
                                </TextInputField.ErrorContainer>
                              )}
                            </div>
                          );
                        },
                      }}
                    />
                  </Label>
                  <span
                    style={{
                      position: "absolute",
                      top: 40,
                      left: 16,
                      fontSize: "1.6rem",
                    }}
                  >
                    $
                  </span>
                </div>
                <Label>
                  YouTube channel URL
                  <TextInputField
                    name="channelUrl"
                    fieldName="YouTube channel URL"
                    register={register}
                    errors={errors}
                    required
                    placeholder="Paste link"
                    style={{
                      background: "none",
                      marginTop: 4,
                      color: "var(--white)",
                    }}
                  />
                </Label>
                <Label>
                  Email address
                  <TextInputField
                    name="email"
                    fieldName="Email"
                    register={register}
                    errors={errors}
                    autoComplete="email"
                    rules={{
                      validate: (v: string) => isEmail(v) || "Invalid email",
                    }}
                    placeholder="Enter email"
                    style={{
                      background: "none",
                      marginTop: 4,
                      color: "var(--white)",
                    }}
                  />
                </Label>
                <ButtonStyled
                  type="submit"
                  disabled={isSubmitting}
                  loader={{ isLoading: isSubmitting, text: "Submitting..." }}
                  style={{ marginTop: 40 }}
                >
                  Submit
                </ButtonStyled>
                <PrivacyText>
                  By submiting information to Everbloom, you are agreeing to our{" "}
                  <a
                    href="http://youtube.everbloom.app/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="http://youtube.everbloom.app/terms-of-service"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms & Conditions
                  </a>
                </PrivacyText>
              </FormStyled>
            </div>
          </Grid>
        </Container>
        <TopLogos />
      </Wrapper>
    </>
  );
};

export default Pair;

export const CurrencyTextInput = styled(CurrencyInput)`
  border: 1px solid var(--borderGray);
  width: 100%;
  padding: 16px;
  font-size: 1.6rem;
  font-family: inherit;
  outline: none;
  border-radius: 4px;
  background: none;
  margin-top: 4px;
  color: var(--white);
  padding-left: 36px;

  &:focus {
    outline: 1px solid var(--borderGray);
  }

  ${({ error }: { error?: boolean }) =>
    error &&
    css`
      border: 1px solid var(--errorRed);
    `}
`;
