import styled from 'styled-components';

const TextHr = styled.div`
  display: flex;
  align-items: center;
  font-family: var(--bodyFontBold);
  font-size: 1.2rem;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.14em;
  text-transform: uppercase;

  &:before {
    content: '';
    flex: 1;
    border-bottom: ${({ thickness }: { thickness?: string }) =>
      thickness ? `${thickness}px solid #767676` : '1px solid #767676'};
    margin: 5px 16px 5px 0;
  }

  &:after {
    content: '';
    flex: 1;
    border-bottom: ${({ thickness }: { thickness?: string }) =>
      thickness ? `${thickness}px solid #767676` : '1px solid #767676'};
    margin: 5px 0px 5px 16px;
  }
`;

export default TextHr;
