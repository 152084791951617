const GoogleLogoIcon = ({ width = 19 }) => {
  return (
    <svg
      width={width}
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1219_39076)">
        <path
          d="M18.5002 9.38854C18.5003 8.76461 18.4493 8.14171 18.3476 7.52612H9.69043V11.0573H14.6523C14.5505 11.623 14.3352 12.1621 14.0195 12.6424C13.7037 13.1226 13.294 13.5339 12.815 13.8515V16.1468H15.7766C17.5115 14.5521 18.5105 12.193 18.5105 9.3874L18.5002 9.38854Z"
          fill="#4285F4"
        />
        <path
          d="M9.68233 18.3669C12.1564 18.3669 14.2478 17.5513 15.7662 16.148L12.8046 13.8527C11.9753 14.4131 10.9171 14.7321 9.68005 14.7321C7.28795 14.7321 5.25466 13.1123 4.52678 10.9446H1.48083V13.3162C2.2473 14.834 3.41958 16.1096 4.86742 17.0012C6.31526 17.8929 7.98196 18.3656 9.68233 18.3669Z"
          fill="#34A853"
        />
        <path
          d="M4.53355 10.9447C4.15079 9.80227 4.15079 8.5662 4.53355 7.42376V5.0647H1.48077C0.833671 6.34151 0.49646 7.75281 0.49646 9.18424C0.49646 10.6157 0.833671 12.027 1.48077 13.3038L4.53355 10.9447Z"
          fill="#FBBC04"
        />
        <path
          d="M9.68216 3.63613C10.3305 3.6233 10.9751 3.73832 11.579 3.97462C12.183 4.21091 12.7345 4.56387 13.202 5.01331L15.8219 2.39339C14.1657 0.828636 11.965 -0.0293922 9.68672 0.00128334C7.98477 0.00248574 6.31663 0.476568 4.86842 1.37063C3.42022 2.2647 2.24892 3.54357 1.48523 5.06456L4.53345 7.43615C5.26134 5.25592 7.29006 3.63613 9.68216 3.63613Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_1219_39076">
          <rect
            width="17.6405"
            height="18"
            fill="white"
            transform="translate(0.679688)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoogleLogoIcon;
