import { useEffect, useLayoutEffect, useRef } from 'react';
import { isAndroid, isIOS, isIOS13, isIPhone13 } from 'react-device-detect';

import useAmplitude from './useAmplitude';

interface Props {
  eventType: string;
  eventProperties?: object | Function;
}

const LogOnMountAmplitude = ({ eventType, eventProperties }: Props) => {
  const { logAmplitudeEvent } = useAmplitude();

  const isMobile = isAndroid || isIOS || isIOS13 || isIPhone13;

  const logEventRef = useRef(logAmplitudeEvent);
  const hasMounted = useRef(false);

  useEffect(() => {
    logEventRef.current = logAmplitudeEvent;
  }, [logAmplitudeEvent]);

  useEffect(() => {
    if (hasMounted.current) return;

    if (isMobile) {
      logEventRef.current?.(
        eventType,
        Object.assign({ platform: 'mobile' }, eventProperties)
      );
    } else {
      logEventRef.current?.(
        eventType,
        Object.assign({ platform: 'desktop' }, eventProperties)
      );
    }

    hasMounted.current = true;
  }, [eventType, eventProperties, isMobile]);

  return null;
};

export default LogOnMountAmplitude;
