import { useRouter } from "next/router";
import styled from "styled-components";

import PairSubmitted from "@/components/Pair/PairSubmitted";
import Pair from "@/components/Pair";
import PairSignin from "@/components/Pair/PairSignin";
import SEO from "@/components/Seo/Seo";
import PairSuccess from "@/components/Pair/PairSuccess";
import PairError from "@/components/Pair/PairError";
import LinkStyled from "@/components/UI/LinkStyled";
import LogOnMountAmplitude from "@/utils/amplitude/LogOnMount";

const Screens = () => {
  const router = useRouter();

  const { screen } = router.query;

  switch (screen) {
    case "signin":
      return <PairSignin />;
    case "success":
      return <PairSuccess />;
    case "error":
      return <PairError />;
    case "submitted":
      return <PairSubmitted />;
    default:
      return <Pair />;
  }
};

const PairPage = () => {
  return (
    <>
      <SEO title={`Everbloom - Invest in Creators`} />
      <div
        style={{
          background: "var(--white)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 70,
        }}
      >
        <a href="https://everbloom.app/creators">
          <img
            src="/logos/logo-navbar.svg"
            alt="logo"
            width={170}
            height={48}
          />
        </a>
      </div>
      <Screens />
      <PairFooter>
        <PairFooterContainer>
          <PairFooterLinks>
            <li>
              <LinkStyled href="/privacy-policy">Privacy</LinkStyled>
            </li>
            <li>
              <LinkStyled href="/terms-of-service">Terms</LinkStyled>
            </li>
          </PairFooterLinks>
          <span>
            &copy; {new Date().getFullYear()} Everbloom. All rights reserved.
          </span>
        </PairFooterContainer>
      </PairFooter>
      <LogOnMountAmplitude
        eventType="Page_Viewed"
        eventProperties={{
          path: "/",
        }}
      />
    </>
  );
};

export default PairPage;

PairPage.getLayout = (page: any) => page;

export const PairFooter = styled.footer`
  background: var(--black);
  color: var(--white);
  font-size: 1.2rem;
  font-family: var(--headingFontBold65);
`;

export const PairFooterContainer = styled.div`
  width: min(100% - 40px, 1440px);
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 0 30px 0;
  margin: 0 auto;
  text-transform: uppercase;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const PairFooterLinks = styled.ul`
  display: flex;
  justify-content: center;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  list-style: none;
  gap: 30px;

  a {
    color: var(--white);
  }
`;
