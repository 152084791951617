import { motion } from "framer-motion";
import styled, { css } from "styled-components";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  placeholder?: string;
  errors: Record<string, any>;
  register: any;
  fieldName?: string;
  autoComplete?: string;
  rules?: object;
  required?: boolean;
}

const regex = /\./;
export const parsedErrors = (name: string, errors: Record<string, any>) => {
  if (!errors) return null;
  if (regex.test(name)) {
    return errors[name.split(".")[0]]?.[name.split(".")[1]];
  }
  return errors[name];
};

const InputField = ({
  name,
  placeholder,
  errors,
  register,
  fieldName,
  autoComplete,
  required = true,
  rules = {},
  ...rest
}: Props) => {
  const inputErrors = parsedErrors(name, errors);

  return (
    <div style={{ position: "relative" }}>
      <InputFieldStyled
        {...rest}
        name={name}
        {...register(name, { ...rules, required })}
        autoComplete={autoComplete}
        error={inputErrors}
        placeholder={placeholder}
      />
      {inputErrors?.type === "required" && (
        <ErrorContainer>
          <ErrorText>
            {fieldName ?? name.charAt(0).toUpperCase() + name.slice(1)} is
            required
          </ErrorText>
        </ErrorContainer>
      )}
      {Object.keys(rules)?.length > 0 && inputErrors?.type === "validate" && (
        <ErrorContainer>
          <ErrorText>{inputErrors?.message}</ErrorText>
        </ErrorContainer>
      )}
    </div>
  );
};

export const InputFieldStyled = styled.input`
  border: 1px solid var(--borderGray);
  background: var(--white);
  width: 100%;
  padding: 16px;
  font-size: 1.6rem;
  font-family: inherit;
  outline: none;
  border-radius: 4px;

  &:focus {
    outline: 1px solid var(--borderGray);
  }

  ${({ error }: { error?: boolean }) =>
    error &&
    css`
      border: 1px solid var(--errorRed);
    `}
`;

export const ErrorContainer = styled(motion.div)`
  position: absolute;
  width: 100%;
  top: 101%;
`;

export const ErrorText = styled.span`
  color: var(--errorRed);
  font-size: 1.2rem;
  font-weight: 500;
  display: block;
  line-height: 1.3;
`;

InputField.Input = InputFieldStyled;
InputField.ErrorContainer = ErrorContainer;
InputField.ErrorText = ErrorText;

export default InputField;
