import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";
import isEmail from "validator/lib/isEmail";
import React, { useState } from "react";
import { useRouter } from "next/router";
import axios from "axios";

import TextInputField from "../UI/Form/TextInputField";
import Label from "../UI/Form/Label";
import TopLogos from "./TopLogos";
import Checkbox from "../UI/Checkbox/Checkbox";

import {
  Wrapper,
  Container,
  Title,
  TitleEiko,
  Tagline,
  ButtonStyled,
} from "./Pair.styles";

import useUser from "@/hooks/useUser";

const PairSuccess = () => {
  const { query, push } = useRouter();

  const { data: user } = useUser();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [selectedOptin, setSelectedOptin] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmitEmail = async (data: any) => {
    try {
      const accessToken = query?.accessToken;

      setIsSubmitting(true);

      await axios.post(
        "/api/me",
        {
          profile: {
            ...user,
            emailAddressAlt: data.email,
            isSubscribedAlt: selectedOptin,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      await axios.post("/api/mailchimp-email", { ...data });

      push(`/?screen=submitted&accessToken=${accessToken}`);
    } catch (error) {
      push(`/?screen=error`);
      setIsSubmitting(false);
    }
  };

  return (
    <Wrapper>
      <Container>
        <Title>
          Success!
          <br />
          <TitleEiko>How can we reach you?</TitleEiko>
        </Title>
        <Tagline>
          Enter your email so that we can follow-up with a proposal.
        </Tagline>
        <form
          onSubmit={handleSubmit(onSubmitEmail)}
          style={{
            width: "min(100% - 40px, 470px)",
            margin: "0 auto",
          }}
        >
          <Label>
            Email address
            <TextInputField
              name="email"
              fieldName="Email"
              register={register}
              errors={errors}
              autoComplete="email"
              rules={{ validate: (v: string) => isEmail(v) || "Invalid email" }}
              placeholder="Enter email"
              style={{
                background: "none",
                marginTop: 4,
                color: "var(--white)",
              }}
            />
          </Label>
          <OptinWrapper
            onClick={() => setSelectedOptin((prevState) => !prevState)}
          >
            <Checkbox
              name="optin"
              checked={selectedOptin}
              style={
                {
                  "--checkedBackground": "var(--greenLight)",
                  "--checkIconColor": "var(--black)",
                } as React.CSSProperties
              }
            />
            <span>I agree to get occasional updates from Everbloom </span>
          </OptinWrapper>
          <ButtonStyled
            type="submit"
            disabled={isSubmitting}
            loader={{ isLoading: isSubmitting, text: "Submitting..." }}
          >
            Submit
          </ButtonStyled>
          <PrivacyText>
            By submiting information to Everbloom, you are agreeing to our{" "}
            <a
              href="http://youtube.everbloom.app/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="http://youtube.everbloom.app/terms-of-service"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
          </PrivacyText>
        </form>
      </Container>
      <TopLogos />
    </Wrapper>
  );
};

export default PairSuccess;

export const PrivacyText = styled.p`
  color: var(--white);
  font-family: var(--bodyFontMedium);
  margin-top: 30px;
  font-size: 1.4rem;
  line-height: 1.3em;

  @media (min-width: 1100px) {
    font-size: 1.6rem;
  }

  a {
    color: var(--greenLight);
  }
`;

export const OptinWrapper = styled.div`
  margin: 40px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.6rem;
  line-height: 1.3em;
  cursor: pointer;

  @media (min-width: 1100px) {
    font-size: 1.6rem;
  }
`;
