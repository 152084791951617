import axios from "axios";
import nookies, { destroyCookie, parseCookies, setCookie } from "nookies";
import { useQueryClient } from "@tanstack/react-query";
import { configureScope } from "@sentry/nextjs";
import { GetServerSidePropsContext } from "next";

import useAmplitude from "@/utils/amplitude/useAmplitude";

export interface Token {
  data: string;
  expiresIn: number;
}

export interface RefreshToken {
  data: string;
  refreshIn: number;
}

// Get token from cookies
export const getTokenFromCookies = () => {
  const token = parseCookies().everbloomToken;

  return token;
};

const useAuth = () => {
  const client = useQueryClient();

  const { logAmplitudeEvent } = useAmplitude();

  const hostname = window.location.hostname;

  // Set token in cookies
  const setToken = (token: Token | undefined) => {
    if (token === undefined) {
      destroyCookie(null, "everbloomToken", { path: "/" });
    } else {
      setCookie(null, "everbloomToken", token.data, {
        maxAge: token?.expiresIn,
        path: "/",
      });
    }
  };

  // Set token in cookies
  const setRefreshToken = (refreshToken: RefreshToken | undefined) => {
    if (refreshToken === undefined) {
      destroyCookie(null, "everbloomRefreshToken");
    } else {
      setCookie(null, "everbloomRefreshToken", refreshToken.data, {
        maxAge: refreshToken?.refreshIn,
        path: "/",
      });
    }
  };

  // Login user
  const login = async (idToken: string) => {
    const { data: userData } = await axios.post(`/api/auth/firebase`, {
      idToken,
    });

    const user = userData?.data?.profile;

    logAmplitudeEvent("Did_Log_In");

    client.setQueryData(["user"], user);

    return {
      token: {
        data: userData.data.accessToken,
        expiresIn: userData.data.expiresIn,
      },
      refreshToken: {
        data: userData.data.refreshToken,
        refreshIn: userData.data.refreshIn,
      },
      username: userData.data.profile?.userName,
      user,
    };
  };

  // Sign up user
  const signup = async (idToken: string, username: string) => {
    // TODO: this endpoint is supposed to be call while the user is editing/typing their userName,
    // to give user feedback whether the userName is still available. Same validation is also being
    // done by the POST /v1/me endpoint, which in this case is not needed here.
    /*
    const { data: validation } = await axios.post(
      `/api/validate`,
      {
        profile: {
          userName: username.toLowerCase(),
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.idToken}`,
        },
      }
    );
    const { data: userProfile } = validation;
    */

    const { data: userData } = await axios.post("/api/auth/firebase", {
      idToken,
    });

    await axios.post(
      "/api/me",
      {
        profile: {
          userName: username.toLowerCase(),
        },
      },
      {
        headers: {
          // Authorization: `Bearer ${userData.data.accessToken}`,
          Authorization: `Everbloom ${userData.data.accessToken}`,
        },
      }
    );
    const user = userData?.data?.profile;

    logAmplitudeEvent("Did_Log_In");

    client.setQueryData(["user"], user);

    return {
      token: {
        data: userData.data.accessToken,
        expiresIn: userData.data.expiresIn,
      },
      refreshToken: {
        data: userData.data.refreshToken,
        refreshIn: userData.data.refreshIn,
      },
    };
  };

  // Login/sign up hook
  const useLogin = () => {
    const requestLogin = async (idToken: string) => {
      const { token, refreshToken, username, user } = await login(idToken);
      setToken(token);
      setRefreshToken(refreshToken);
      return { username, token, user };
    };

    const requestSignup = async (idToken: string, username: string) => {
      const { token, refreshToken } = await signup(idToken, username);
      setToken(token);
      setRefreshToken(refreshToken);
      return { token, username };
    };

    return { requestLogin, requestSignup };
  };

  return { useLogin, setToken, setRefreshToken };
};

// Get token hook
export const useToken = () => {
  const token = getTokenFromCookies();

  if (!token) {
    return undefined;
  }

  return token;
};

export const logout = async (logAmplitudeEvent: (arg: string) => void) => {
  logAmplitudeEvent("Logout");
  destroyCookie(null, "everbloomToken", { path: "/" });
  destroyCookie(null, "everbloomRefreshToken", { path: "/" });
  const auth = (await import("@/services/firebase")).auth;
  await auth.signOut();
  window.indexedDB.deleteDatabase("firebaseLocalStorageDb");
  configureScope((scope) => scope.setUser(null));
  document.location.href = "/login";
};

export default useAuth;

// Get token in SSR
export const getTokenSSR = (
  ctx: GetServerSidePropsContext
): string | undefined => {
  const cookies = nookies.get(ctx);

  return cookies?.everbloomToken;
};
