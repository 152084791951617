import Button from "../UI/Button";
import {
  Container,
  StepsHeading,
  Tagline,
  Title,
  TitleEiko,
  Wrapper,
} from "./Pair.styles";
import TopLogos from "./TopLogos";

const PairSubmitted = () => {
  return (
    <Wrapper>
      <Container>
        <Title>
          <TitleEiko>Thank you for</TitleEiko>
          submitting your info
        </Title>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src="/images/star-yt.png"
            alt="Pairing"
            style={{ marginBottom: 20 }}
            width={175}
            height={175}
          />
          <StepsHeading>What&apos;s next?</StepsHeading>
          <Tagline>
            In around 48-72h you will find an email
            <br /> from us in your inbox. Can&apos;t wait to
            <br /> show you what we come up with!
          </Tagline>
          <a
            href="https://everbloom.app/creators/"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              style={{
                background: "var(--greenLight)",
                color: "var(--black)",
                fontSize: "1.6rem",
                letterSpacing: 0,
                fontFamily: "var(--headingFontBold65)",
              }}
            >
              Back to browse
            </Button>
          </a>
        </div>
        <TopLogos />
      </Container>
    </Wrapper>
  );
};

export default PairSubmitted;
