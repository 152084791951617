import { useRouter } from "next/router";

import TopLogos from "./TopLogos";
import GoogleButton from "./GoogleButton";

import {
  ButtonWrapper,
  Container,
  StepsHeading,
  Tagline,
  Title,
  TitleEiko,
  Wrapper,
} from "./Pair.styles";

const PairError = () => {
  const { query } = useRouter();

  return (
    <Wrapper>
      <Container>
        <Title>
          We&apos;re sorry,
          <br /> <TitleEiko>Something went wrong.</TitleEiko>
        </Title>
        <Tagline style={{ color: "crimson" }}>{query.message}</Tagline>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src="/images/flag-yt.png"
            alt="Pairing"
            style={{ marginBottom: 20 }}
            width={175}
            height={175}
          />
          <StepsHeading>Try again?</StepsHeading>
          <Tagline>
            This process may take a few minutes, but if you don&apos;t see a
            success screen soon, you can try pairing again.
          </Tagline>
          <ButtonWrapper>
            <GoogleButton />
          </ButtonWrapper>
          <div
            style={{
              textAlign: "center",
              marginTop: 40,
              fontSize: "1.2rem",
              lineHeight: "1.3em",
            }}
          >
            <span>
              Everbloom does not sell your data to third-parties.
              <br /> You can unpair your account at any time.
            </span>
          </div>
        </div>
        <TopLogos />
      </Container>
    </Wrapper>
  );
};

export default PairError;
