import GoogleLogin from "react-google-login";
import { useRouter } from "next/router";
import styled from "styled-components";
import { captureException } from "@sentry/nextjs";

import Button from "@/components/UI/Button";
import GoogleLogoIcon from "../UI/Icons/GoogleLogoIcon";

import AuthService from "@/services/auth";

import { baseUrl } from "config";
import axios, { AxiosError } from "axios";
import useAmplitude from "@/utils/amplitude/useAmplitude";

const GoogleButton = () => {
  const { push } = useRouter();

  const { logAmplitudeEvent } = useAmplitude();

  const handleGooggleLogin = async (googleLogin: any) => {
    try {
      const { code } = googleLogin;
      const data = await AuthService.authGoogle({
        baseUrl,
        oAuthCode: code,
        redirectUri: baseUrl,
      });

      await axios.post("/api/discord", {
        email: data.profile!.emailAddress,
        fullName: data.profile!.fullName,
        channelUrl: data.profile!.socials.youtube.channelUrl,
        totalViews: String(data.profile!.statistics.youtube.viewsCount.total),
      });

      logAmplitudeEvent("Creator_Auth_Finish");

      push(`/?screen=success&accessToken=${data.accessToken}`);
    } catch (error) {
      captureException(error);

      if (error instanceof AxiosError) {
        push(`/?screen=error&message=${error?.response?.data.status.message}`);
        return;
      }
    }
  };

  return (
    <span
      onClick={() => {
        logAmplitudeEvent("Creator_Auth_Start");
      }}
    >
      <GoogleLogin
        accessType="offline"
        clientId={process.env.NEXT_PUBLIC_GOOGLE_WEB_CLIENT_ID!}
        onSuccess={handleGooggleLogin}
        prompt="consent"
        responseType="code"
        scope="https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtubepartner-channel-audit https://www.googleapis.com/auth/youtubepartner-content-owner-readonly https://www.googleapis.com/auth/yt-analytics-monetary.readonly"
        render={({ disabled, onClick }) => (
          <Button
            disabled={disabled}
            onClick={onClick}
            variant="secondary"
            style={{ textTransform: "none", letterSpacing: "0.05em" }}
          >
            <ButtonInnerDiv>
              <GoogleLogoIcon />
              <ButtonText>Sign in with Google</ButtonText>
            </ButtonInnerDiv>
          </Button>
        )}
      />
    </span>
  );
};

export default GoogleButton;

export const ButtonInnerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const ButtonText = styled.span`
  line-height: 1;
`;
