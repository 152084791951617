import Button from '../UI/Button';
import {
  Container,
  StepsHeading,
  Tagline,
  Title,
  Wrapper,
} from './Pair.styles';
import TopLogos from './TopLogos';

const PairSignin = () => {
  return (
    <Wrapper>
      <Container>
        <Title>
          <strong>Pairing</strong> your account
        </Title>
        <Tagline>
          You should see a pop-up on the screen now. If not, make sure to turn
          off ad
          <br /> blockers and make sure your browser allows pop-ups.
        </Tagline>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src="/images/flag-yt.png"
            alt="Pairing"
            style={{ marginBottom: 20 }}
            width={175}
            height={175}
          />
          <StepsHeading>Having trouble?</StepsHeading>
          <Tagline>
            This process may take a few minutes,
            <br /> but if you don&apos;t see a success screen soon,
            <br /> you can try pairing again.
          </Tagline>
          <Button
            style={{
              background: 'var(--white)',
              color: 'var(--black)',
              width: 'fit-content',
              textTransform: 'none',
              letterSpacing: 0,
              fontSize: '1.6rem',
            }}
            icon={
              <img
                src="/icons/onboarding/onboarding-google-icon.svg"
                alt="Google"
              />
            }
          >
            Sign in with Google
          </Button>
        </div>
        <TopLogos />
      </Container>
    </Wrapper>
  );
};

export default PairSignin;
