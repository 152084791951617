import styled from 'styled-components';

const Label = styled.label`
  display: block;
  font-size: 1.4rem;
  margin-bottom: 5px;

  & > span {
    color: var(--gray2);
  }

  @media (min-width: 768px) {
    font-size: 1.6rem;
  }
`;

export default Label;
