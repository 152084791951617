import { ReactElement } from "react";
import {
  Control,
  FieldValues,
  InternalFieldErrors,
  RegisterOptions,
  UseFormRegister,
  useFormState,
  useWatch,
} from "react-hook-form";

// Eg: When using <SelectInput /> with `react-use-form`

/* <Controller
{...{
  control,
  register,
  name: 'country',
  rules: { required: true },
  render: ({ onChange, name, errors }) => (
    <div style={{ position: 'relative', display: 'inherit' }}>
      <SelectInput
        name="country"
        label="Country"
        items={countries}
        selectedKey={getValues(name)}
        onChange={(value) => {
          onChange(value);
          setIsAmerica(value === 'CA' || value === 'US');
        }}
        initialValue="Select a country"
        errors={errorsForm[name]}
      >
        {(item) => <Item key={item.value}>{item.text}</Item>}
      </SelectInput>
      {errors?.type === 'required' && (
        <ErrorContainer>
          <ErrorText>Country is required</ErrorText>
        </ErrorContainer>
      )}
    </div>
  ),
}}
/> */

interface ControllerProps {
  control: Control;
  register: UseFormRegister<FieldValues>;
  name: string;
  rules: RegisterOptions;
  render: (ctx: {
    value: any;
    onChange: (value: any) => void;
    name: string;
    errors: { type: string; ref: any };
  }) => ReactElement;
}

const Controller = ({
  control,
  register,
  name,
  rules,
  render,
}: ControllerProps) => {
  const props = register(name, rules);

  const value = useWatch({
    control,
    name,
  });

  const { errors } = useFormState({
    control,
    name,
  });

  return render({
    value,
    onChange: (value) =>
      props.onChange({
        target: {
          name,
          value,
        },
      }),
    name: props.name,
    // @ts-ignore
    errors: errors[name],
  });
};

export default Controller;
