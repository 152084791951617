import { useMemo } from 'react';

import { useAmplitudeContext } from './AmplitudeProvider';

const useAmplitude = () => {
  const { amplitudeInstance } = useAmplitudeContext();

  return useMemo(() => {
    const logAmplitudeEvent = <T extends string>(
      eventType: T,
      eventProperties?: object
    ) => {
      amplitudeInstance?.logEvent(eventType, eventProperties);
    };

    return {
      logAmplitudeEvent,
      amplitudeInstance,
    };
  }, [amplitudeInstance]);
};

export default useAmplitude;
