import { motion } from 'framer-motion';
import styled from 'styled-components';
import Button from '../UI/Button';

export const Wrapper = styled.div`
  background: var(--black);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 60px 16px;
  max-width: 1100px;
  margin: 0 auto;
  color: var(--white);

  @media (min-width: 1100px) {
    padding: 100px 20px;
  }
`;

export const Title = styled.h1`
  text-transform: uppercase;
  text-align: center;
  color: var(--white);
  font-size: 3rem;

  @media (min-width: 1100px) {
    font-size: 5.5rem;
    white-space: nowrap;
  }

  strong {
    color: var(--greenLight);
  }
`;

export const TitleEiko = styled.span`
  font-family: var(--ppEikoThin);
  font-weight: 500;
  margin-top: 8px;
  display: block;
`;

export const Tagline = styled.p`
  color: var(--white);
  text-align: center;
  margin: 0 auto;
  margin-bottom: 40px;
  font-size: 1.6rem;
  max-width: 30ch;
  line-height: 1.3em;

  @media (min-width: 1100px) {
    max-width: 50ch;
    font-size: 1.8rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 40px;
`;

export const StepsWrapper = styled.div``;

export const StepsContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
  margin-bottom: 60px;

  @media (min-width: 1100px) {
    gap: 40px;
  }
`;

export const StepsCount = styled.span`
  font-size: 5rem;
  color: var(--greenLight);
  font-family: var(--ppEikoThin);
  font-weight: 500;

  @media (min-width: 1100px) {
    font-size: 8rem;
  }
`;

export const StepsHeading = styled.h2`
  text-transform: uppercase;
  font-size: 1.6rem;

  @media (min-width: 1100px) {
    /* font-size: 2.4rem; */
  }

  span {
    color: var(--greenLight);
  }
`;

export const StepsText = styled.p`
  color: var(--black85);
  font-size: 1.4rem;

  @media (min-width: 1100px) {
    font-size: 1.8rem;
  }
`;

export const Overlay = styled(motion.div)`
  background: var(--black);
  /* position: fixed;
  inset: 0;
  z-index: 100000; */
`;

export const OverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  height: 100%;
`;

export const ButtonStyled = styled(Button)`
  margin-top: 20px;
  font-size: 1.6rem;
  font-family: var(--headingFontBold65);
  letter-spacing: 0;

  &:not(:disabled) {
    background: var(--greenLight);
    color: var(--black);
  }
`;

export const FormStyled = styled.form`
  margin-top: 40px;

  & label {
    margin-bottom: 30px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 40px;
  grid-gap: 20px;

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 80px;
  }
`;
